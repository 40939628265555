<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width="500px"
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="组名称" prop="name">
                <el-input v-model.trim="form.name" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="权限码" prop="code">
                <el-input v-model="form.code" autocomplete="off"></el-input>
            </el-form-item>


            <el-form-item label="状态" prop="isDisable">
                <el-checkbox v-model="form.isDisable">
                    禁用
                </el-checkbox>
            </el-form-item>

            <el-form-item label="描述" prop="desc">
                <el-input v-model="form.desc" autocomplete="off"></el-input>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { AddUserGrop, EditUserGrop } from '@/api/user';
    export default {
        name: 'UserGroupEdit',
        data() {
            return {
                form: {
                    id: 0,
                    name: '',
                    code: '',
                    isDisable: false,
                    desc: ''
                },
                rules: {
                    name: [{ required: true, trigger: 'blur', message: '请输入组名称' }],
                    code: [{ required: true, trigger: 'blur', message: '请输入权限码' }],
                    desc: [{ required: true, trigger: 'blur', message: '请输入描述' }],
                },
                title: '',
                dialogFormVisible: false,
            }
        },
        created() { },
        methods: {
            showEdit(row) {
                if (!row) {
                    this.title = '添加'
                } else {
                    this.title = '编辑'
                    this.form = Object.assign({}, row)
                }
                this.dialogFormVisible = true
            },
            close() {
                this.$refs['form'].resetFields()
                this.form = this.$options.data().form
                this.dialogFormVisible = false
            },
            save() {
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        const { isSucceed, message } = await AddUserGrop(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    } else {
                        const { isSucceed, message } = await EditUserGrop(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    }
                    this.$emit('fetch-data');
                })
            },
        },
    }
</script>
